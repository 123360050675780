@import './../../assets/styles/colors.scss';

.rdt_ExpanderRow {
    margin-left: 30px;
}
.rdt_TableCell{
    border-right: 1px solid #eee;
}
a{
    color: $text-color;
    &:hover{
        text-decoration: none;
    }
}