.create-form {
  display: flex;
  flex-wrap: wrap;
  padding:1em;
  .form-fields {
    margin-right: 3rem;
  }
}

.add-enter {
  padding: 15px;
}
