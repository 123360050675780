.specific-objectives{
    .progress {
        width: 150px;
        height: 150px !important;
        float: left; 
        line-height: 150px;
        background: none;
        margin: 20px;
        box-shadow: none;
        position: relative;
      }
      .progress:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 12px solid #fff;
        position: absolute;
        top: 0;
        left: 0;
      }
      .progress>span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
      }
      .progress .progress-left {
        left: 0;
      }
      .progress .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 12px;
        border-style: solid;
        position: absolute;
        top: 0;
      }
      .progress .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
      }
      .progress .progress-right {
        right: 0;
      }
      .progress .progress-right .progress-bar {
        left: -100%;
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
        animation: loading-1 1.8s linear forwards;
      }
      .progress .progress-value {
        width: 90%;
        height: 90%;
        border-radius: 50%;
        background: #000;
        font-size: 24px;
        color: #fff;
        line-height: 135px;
        text-align: center;
        position: absolute;
        top: 5%;
        left: 5%;
      }
      .progress.blue .progress-bar {
        border-color: #049dff;
      }
      .progress.blue .progress-left .progress-bar {
        animation: loading-2 1.5s linear forwards 1.8s;
      }
      
      @keyframes loading-1 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      @keyframes loading-2 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(144deg);
          transform: rotate(144deg);
        }
      }
      @keyframes loading-3 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
        }
      }
}

.app-card{
  min-height: 150px;
}