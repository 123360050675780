@import './assets/styles/global.scss';
*{
    box-sizing: border-box;
    list-style: none;
}

.App{
    color: #555453;
    // background: #0175b6;
}
.br-input{
    padding: 8px;
    border-radius: 7px;
    border: solid 1px #f6fafe;
    background:#eef5fd;
}

.cursor-pointer{
    cursor: pointer;
}

.btn-behaviour{
    cursor: pointer;
}
.w100{
    width:100%;
}
.flex{
    display: flex;
    &.justify-content-between{
        justify-content: space-between;
    }
}
.p-2{
    padding: 10px;
}

.t-input{
    outline: none;
    border: none;
    background:transparent;

    &:focus{
        box-shadow: none;
    }
}

.bg-lb{
    background: #EEF5FD;
}

.action-icons {
    margin-right: 10px;
    right: 0;
    font-size: 16px;
    cursor: pointer;
    color: $primary-color;
  }

  .bg-orange{
    background:$secondary-color
  }

  .bg-orange-light{
    background:$secondary-color-light
  }

  .bg-light-grey{
    background: $light-gray-color;
  }
