.recieve-container {
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.activities-container {
  border-radius: 12px;
  padding: 16px;
  min-height: 300px;
}

.project-list-container {
  border-radius: 12px;
  padding: 16px;
  overflow: auto;
  column-gap: 32px;
  .progress {
    height: 8px;
    border-radius: 0px;
  }
}

@media (max-width: 1200px) {
  .recieve-container {
    :nth-child(2) {
      align-self: center;
    }
  }
  .margin {
    margin-bottom: 16px;
  }
}
