.sdg-wrapper {
  position: relative;
  text-align: center;
  width: 300px;
  // max-height: 300px;
  // width: 500px;
  margin: 0 auto;

  .circle-pop {
    position: absolute;
    border-radius: 50%;
    background: #ccc;
    width: 175px;
    height: 175px;
    display: block;
    z-index: 999999;
    /* top: 208px; */
    /* left: 237px; */
    text-align: center;
    left: 21.0%;
    top: 20.9%;
  }

  .close {
    color: #fff !important;
    font-size: 24px !important;
    font-weight: bold !important;
    margin-top: 12px;
    position: absolute;
    text-align: center;
    margin-left: -5px;
   
    cursor: pointer;
  }

  .circle-content {
    position: relative;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    height: 70%;
    top: 15%;
    .text-content {
    padding-top:10px ;
      .heading{
        color: #ffffff;
        font-size: 16px;
        font-weight: bolder;
      }
      .totalCalculate{
        color : #fff;
        font-size: 12px;
        font-weight: bold;
      }
      a{
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .sdg-wrapper{
    width: 200px;
    .circle-pop {
      width: 115px;
      height: 115px;
    }
  
    .close {
      font-size: 16px !important;
      margin-top: 10px;
    }

    h3{
      font-size: 10px;
    }
    h2{
      font-size: 12px;
    }
  }
}
