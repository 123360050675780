@import './../../assets/styles/global.scss';

.form-stepper {
  .step-wizard {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stepper-content{
    box-shadow: 1px 1px 9px 1px rgba(1,117,182,0.28);
    padding: 10px 15px 25px;
    border-radius: 6px;
    position: relative;
  }

  .step-wizard-list {
    background: #fff;
    color: #333;
    list-style-type: none;
    border-radius: 10px;
    display: flex;
    padding: 20px 10px;
    position: relative;
    z-index: 10;
    overflow: auto;
  }

  .step-wizard-item {
    // padding: 0 20px;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 120px;
    position: relative;
    color: #666;
    &:before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background-color: $light-gray-color;
      border-radius: 50%;
      position: absolute;
      right: 0;
      top: 15px;
    }
    &:last-child:before {
      display: none;
    }
  }

  .step-wizard-item + .step-wizard-item {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 19px;
      background: $light-gray-color;
      width: 100%;
      height: 2px;
      transform: translateX(-50%);
      z-index: -10;
    }
    &.current-item:after {
      background: $light-gray-color;
    }
  }

  .progress-count {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    color: $light-gray-color;
  }

  .progress-count:after {
    content: "";
    height: 40px;
    width: 40px;
    background: #0175b6;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -10;
  }

  .progress-label {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
  }

  .current-item ~ .step-wizard-item .progress-label {
    opacity: 0.5;
  }

  .progress-count:after {
    background: #fff;
    border: 2px solid $light-gray-color;
  }

  .current-item {
    .progress-count {
      color: #fff;
      &:after {
        border: 2px solid #0175b6;

        background: #0175b6;
        background: #0175b6;
      }
    }
    .progress-label {
      color: #0175b6;
    }
  }

  .stepper-action {
    text-align: right;
    margin: 20px 6px 0;

    button {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      border: none;
      color: white;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px auto;
      border-radius: 6px;
      margin-left: 10px;
      min-width: 150px;
      cursor: pointer;
      transition: 0.5s all;
      &:hover {
        background-color: $primary-color-dark;
      }
      &.btn-primary {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
          background-color: $primary-color-dark;
        }
      }
      &.btn-outline {
        background-color: transparent !important;
        color: $primary-color;
      }
      &:disabled {
        background-color: $light-gray-color;
        border-color: $light-gray-color;
        cursor: not-allowed;
      }
    }
  }
}
