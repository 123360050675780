.wrap {
  background-color: #fff;
  color: #fff;
  // padding-left: 0.7rem;
  box-sizing: border-box;
  // height: 100vh;
  width: 18rem;

  .logo {
    display: flex;
    // justify-content: center;
    margin-left: 20px;
    align-items: flex-end;
    min-height: 6rem;

    img {
      max-width: 180px;
      width: 100%;
      margin: 0px auto;
    }
  }

  .mobileLogo {
    display: none;
  }

  .line1 {
    height: 1px;
    margin: 1rem 0;
    color: inherit;
    background-color: #ccc;
    border: 0;
    opacity: 0.25;
  }

  .copyright{
    // position: absolute;
    bottom: 10px;
    left: 30px;
  }

  .items {
    margin-bottom: auto;
    flex-direction: column;
    display: flex;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    margin-top: 0;
    color: #fff;

    .nav-link {
      margin-bottom: 10px;
      border-bottom: 1px solid #eee;
      list-style: none;
      padding: 0px;
      display: flex;
      align-items: center;
      // background-color: #0075b6;
      color: #0075b6;
      position: relative;
      flex-wrap: wrap;

      .active {
        // background-color: #fff;
        // background: transparent linear-gradient(102deg, #53ABDA 0%, #0A78B4 100%) 0% 0% no-repeat padding-box;
        background: #2275b6;
        width: 100%;
        color: #fff;
        border-radius: 2rem 0 0 2rem;
        border-radius: 0.7rem;
        padding: 1em;
        border-bottom: none;

        &::after,
        &::before {
          // content: " ";
          position: absolute;
          top: -50px;
          right: 0;
          width: 20px;
          height: 50px;
          border-radius: 0 0 25px 0;
        }

        &::before {
          top: auto;
          bottom: -50px;
          border-radius: 0 25px 0 0;
          z-index: 1;
        }

        &::after {
          box-shadow: 0 25px 0 0 #fff;
        }

        &::before {
          box-shadow: 0 -25px 0 0 #fff;
        }

        .child-items {
          display: inherit;
        }

        svg {
          fill: #fff;
        }
      }

      a {
        color: #666;
        font-weight: 500;
        padding: 1rem 0.7rem;
        display: block;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        svg {
          fill: #666;
        }

        span {
          padding-right: 1rem;
        }
      }

      &.active.has-children {
        background-color: #fff;
        // border-top-left-radius: 20px;
        // border-bottom-left-radius: 20px;

        >.active {
          color: #fff;
        }
      }

      &.active {
        .child-items {
          display: block;

          li.active {
            padding: 0rem 1em 1rem;
          }

          .active {
            // padding: 0.7rem 1em;
            background-color: transparent;
            color: #2275b6;
            svg{
              fill: #2275b6;
            }
            &:before,
            &:after {
              display: none;
            }
          }
        }
      }
    }

    &.child-items {
      display: none;
      padding: 0;

      .nav-link {
        background-color: #fff !important;
        padding: 1rem 1rem 1rem 1.5rem !important;
        // border-radius: 2rem 0 0 2rem;
        border: none;

        .active {
          border-radius: 0;
          background-color: transparent;
        }

        a {
          padding: 0;
          color: initial;
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .wrap {
    width: 70px;
    padding: 0;

    .logo {
      display: none;
    }

    .mobileLogo {
      display: contents;

      img {
        margin: 8px;
        width: 50px;
      }
    }

    .line1 {
      display: none;
    }

    .items {
      padding: 0 0 0 6px;

      .nav-link {
        a {
          padding: 0.5rem;

          .title {
            display: none;
          }


        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .wrap {
    width: 50px;
  }

  .wrap .mobileLogo img {
    width: 35px;
  }

  .wrap .items .nav-link a span {
    padding-right: 0;
  }

  .wrap .items .nav-link .active::after,
  .wrap .items .nav-link .active::before {
    width: 0;
  }

  .wrap .items .nav-link .active {
    padding: 0.7em;
  }
}