.header {
  .header-title{
    font-size: 22px;
    font-family: Roboto,sans-serif;
    font-weight: 900;
    color: #000;
  }
  .right {
    display: flex;
    justify-content: flex-end;

    .notif-wrapper {
      .icon {
        margin-right: 1rem;
        background-color: #fff;
        border-radius: 100px;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
        display: flex;
        // margin-top: 0.5rem;
        // span {

        // position: absolute;
        // z-index: 9;
        // top: 10px;
        // margin-right: -10px;
        // }
        .dot {
          right: 6px;
        }
      }
    }

    .dropdown {
      display: flex;
      gap: 0.2rem;
      width: 43%;
      .user-image {
        width: 36px;
        height: 36px;
        object-fit: contain;
      }
      .form-control {
        font-size: 14px;
        font-weight: 500;
        font-family: Roboto,sans-serif;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
      .menu-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .menu-trigger {
        background: #ffffff;
        border-radius: 2rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 6px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        border: none;
        vertical-align: middle;
        transition: box-shadow 0.4s ease;
        margin-left: auto; /* Strictly for positioning */
      }

      .menu-trigger:hover {
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
      }

      .menu-trigger span {
        font-weight: 700;
        vertical-align: middle;
        font-size: 14px;
        margin: 0 10px;
      }

      .menu-trigger img {
        border-radius: 90px;
      }

      .menu {
        background: #ffffff;
        border-radius: 8px;
        position: absolute;
        top: 60px;
        right: 0;
        width: 300px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
      }

      .menu.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .menu li {
        border-bottom: 1px solid #dddddd;
      }

      .menu li a {
        text-decoration: none;
        color: #333333;
        padding: 15px 20px;
        display: block;
      }
    }
  }
}
