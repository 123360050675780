@import "./../../assets/styles/global.scss";

.smart-indicator {
  display: grid;
  border-radius: 6px;
  .action-icons {
    cursor: pointer;
    font-size: 18px;
    margin: 10px 2px;
    color: $primary-color;
    &:hover {
      color: $primary-color-dark;
    }
  }
}

.smart-indicator-modal {
  .ReactModal__Content {
    max-height: 400px;
  }
  h3,
  h4,
  h5,h6 {
    font-weight: bold;
    color: $primary-color;
  }
  .action-icons {
    position: absolute;
    margin-right: 20px;
    right: 0;
    font-size: 20px;
    cursor: pointer;
    color: $primary-color;
  }
  label{
    color:$primary-color;
    font-weight: bold;
  }
}

.smart-ind-list{
  padding-left: 20px;
  li{
    border-bottom: 1px solid #eee;
    padding-bottom: 3px;
    margin-bottom: 2px;
    &:last-child{
      border: none;
      margin-top: 10px;
    }
  }
}
