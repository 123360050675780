@import "./../../assets/styles/global.scss";

.project-summary-col {
  border-right: 2px dashed $primary-color-200;
  font-size: 14px;
}
.projectTable{
  box-shadow: 1px 1px 9px 1px rgba(1,117,182,0.28);
  padding: 0px;
  border-radius: 6px;
  position: relative;
}
.project-menu-wrapper {
  gap: 16px;
  margin-top: 45px;
  flex-wrap: wrap;
  .card {
    text-align: center;
    align-items: center;
    // border: none;
    width: 130px;
    padding: 30px 5px;
    box-shadow: 0px 3px 12px #0000002e;
    border-radius: 6px;
    margin-bottom: 15px;
    transition: 0.5s all;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 3px 15px rgba(1, 117, 182, 0.4);
      position: relative;
      transform: translateY(-10px);
    }
    img {
      max-width: 50px;
      height: 50px;
      font-size: 13px;
      cursor: pointer;
    }
    label {
      color: $primary-color;
      margin-bottom: 0;
      font-size: 13px;
      line-height: 15px;
      margin-top: 5px;
      width: 85%;
      cursor: pointer;
    }
  }
}

.map-icon {
  width: 85px;
}
.externalSecondClass{
  margin-left: 10vw;
}

.album-wrapper {
  gap: 10px;
  .other-images {
    display: flex;
    gap: 8px;
    flex-direction: row;
    flex-wrap: wrap;
    img {
      width: 150px;
      height: 150px;
    }
    .view-more-images {
      justify-content: center;
      align-items: center;
      width: 150px;
      display: flex;
      flex-direction: column;
      transition: 0.5s all;
      cursor: pointer;
      &:hover {
        background-color: #0075b638;
        box-shadow: 3px 3px 5px 1px rgba(167,205,237,0.7);
      }
    }
  }
}
