.main {
  .charts {
    display: grid;
    gap: 20px;
    padding: 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 250px;
    justify-content: space-evenly;

    .content {
      box-shadow: 0 10px 15px -5px rgba(150, 170, 180, 0.5);
      padding: 15px 10px;
      border-radius: 10px;

      &:nth-child(5) {
        grid-column: 1 / span 2;
      }
      &:nth-child(6) {
        grid-column: 3/-1;
        grid-row: 2 / span 2;
      }
    }
  }

  @for $i from 1 through 17 {
    .sdg-#{$i} #sdg-#{$i} > path {
      fill: #ccc;
    }
  }
}
