@import '../../assets/styles/colors.scss';


.records-table {
  // margin-top: 2rem;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $light-gray-color;

  th {
    text-transform: capitalize;
    padding: 10px;
    background: $primary-color-light;
    color: $text-color;
  }

  tr{
    .icon{
      span{
        margin-left: 5px;
      }
    }
  }
  td {
    padding: 10px;
  }
  tr:nth-child(even) {
    background-color: #F9F9F9;
  }
}
