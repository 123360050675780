.wrapper {
  &.overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(5,5,5,0.20);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 100%;
    z-index: 9;
    &.light{
        background: rgba(255,255,255,0.2);
        color: #000;
    }
  }
}
