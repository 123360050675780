.form {

  .field-wrapper{
    font-size: 14px;
    position: relative;
  }

  textarea{
    width: 100%;
    font-family: inherit;
  }

  input, textarea{
    border-color: #ACD2E8 !important;
    color: #7f7f7f;
    margin-bottom: 6px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ACD2E8;
  }

  .dropdown-input,
  textarea{
    border-color: #ACD2E8 !important;
    background-color: #EFF5FD !important;
  }

  .css-1s2u09g-control{
    background-color: transparent !important;
  }

  button{
    padding: 6px 18px;
    font-size: 14px;
  }

  // .dropdown-input *{
  //   background-color: transparent !important;
  // }

  .p8 {
    padding: 0 8px;
  }

  label {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 6px;
    margin-top: 10px;
    font-size: 14px;

    &.pb2 {
      // padding-bottom: 1.5em;
    }
  }

  .col-6 {
    width: 49%;
  }

  .col-12{
    width: 100%;
  }

  .position-right{
    margin-left: auto;
    padding-right: 0;
    margin-right: 0;
  }


  >div {
    // min-width: 48%;
    flex-wrap: wrap;

    > :nth-child(2) {
      min-width: 10rem;
      input {
        width: 100%;
      }
    }

    > :first-child {
      @media all and (max-width:800px) {
        min-width: 9rem;
      }
    }

  }
}

.search {
  .form {
    >div {
      > :last-child {
        max-width: 2rem;
        padding: 1rem;
      }
    }
  }
}