.tableInput {
  min-width: 100%;
  border-spacing: 3px;
//   background-color: #eaeaea;
border: 1px solid #ddd;
border-radius: 6px;
padding: 4px;
background: #f9f9f9;
border-collapse: initial;
  thead {
    color: #777777;
  }
  tbody {
    background: #fff;
  }
  tr {
    th,
    td {
      padding: 6px;
      max-width: 100px;
      span {
        cursor: pointer;
        padding: auto 0px;
      }
    }

    th{
        padding-bottom: 0;
    }

    td {
      color: #555;
      background-color: #f9f9f9;
      margin: 0px;
      border-collapse: collapse;
      padding: 2px 6px;
      vertical-align: top;
      input {
        width: 100%;
        display: flex;
        // border:none;
        // background: transparent;
        // padding:6px 10px;
        &:focus {
          background: #fff;
        }
      }
      &.input-wrap {
        // background: #f2f7b7;
        // box-shadow: 0 0 5pt 0.5pt #d3d3d3;
        border-radius: 3px;
        padding: 2px;
      }
      &.act-wrap {
        width: 4rem;

        .act {
          min-height: 2.5rem;
          display: flex;
          justify-content: center;
          min-width: 3rem;
          gap: 4px;
          button {
            padding: 0px 12px;
            // margin-right: 8px;
            min-width: 2rem;
            font-size: 1em;
            border-radius: 4px;
            transition: 0.5s all;
            &:hover{
                background: #fff;
            }
          }
        }
      }
      > div {
        min-width: 100px;
      }
      > div.act {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
      }
    }
  }
  .dropdown {
    input {
      min-width: 150px !important;
    }
    &[class*="-container"] {
      min-width: inherit !important;
      width: 165px !important;
    }
    & [class*="-control"] {
      width: initial !important;
    }
  }
  .css-14el2xx-placeholder{
    white-space:pre;
  }
}

@media (max-width:1116px) {
  .tableInput{
    height: 150px;
  }
  .table-overflow{
    overflow: auto;

  }
  
}