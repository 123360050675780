.beneficiaries{

    table{
        border: none;
        thead{
            border: none;
            tr{
                border: none;
                border-radius: 10px;
                background-color: #EEF5FD;
                th{
                    color: black;
                }
            }
        }
        td{
            border: none;
            background: white;
            border-bottom: 1px solid silver;
        }
    }
}
.externalClass{
    margin-right: 11vw;
}
.beneficiaryTable{
    box-shadow: 1px 1px 9px 1px rgba(1,117,182,0.28);
    padding: 10px 15px 25px;
    border-radius: 6px;
    position: relative;
  }