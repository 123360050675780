.row-container {
  // max-height: 300px;
  // height: 26.8rem;
  .itemContainerLast {
    border-radius: 12px;
    padding: 16px;

  }
}

.sector-buttons {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

.sector-buttons .active {
  font-weight: 700;
  color: #4b4279;
}



@media (max-width: 1200px) {
  .margin {
    margin-bottom: 16px;
  }
}
