.col-map {
  height: 100vh;
}
.activity-map-container {
  border-radius: 12px;
  padding: 16px 16px 0px 16px;

  .orange-dot {
    color: orange;
  }
  .sky-blue {
    color: skyblue;
  }
  .green-dot {
    color: green;
  }
  .leaflet-control-attribution.leaflet-control {
    display: none;
  }

  .leaflet-touch .leaflet-control-layers,
  .leaflet-touch .leaflet-bar {
    border: none;
    box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }
}

.filter-container {
  border-radius: 12px;
  padding: 16px;
  .main-filter-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .drop-down-container {
      display: flex;
      flex-direction: column;
      .drop-down {
        border-radius: 8px;
        padding: 4px;
        background-color: rgb(227, 241, 249);
        border: none;
        border: 2px solid rgb(111, 171, 195);
      }
    }
    .filter-field-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
      .fileter-field {
        border-radius: 8px;
        padding: 4px;
        background-color: rgb(227, 241, 249);
        border: none;
        border: 2px solid rgb(111, 171, 195);
      }
    }
  }
}
