.btn-wrapper{
    justify-content: right;
    padding-right: 2em;
    > div{
        width:auto;
        display: flex;
    }
    .btn{
        color: #fff;
        line-height: 2em;
        width: 100%;
        border-radius: 6px;
        background:#066aa2;
        border:none;
        &:hover{
            cursor: pointer;
        }
    }
}
