.text-noc {
  font-size: 1.5rem;
  color: white;
  font-weight: 900;
  font-family: Roboto Flex, sans-serif;
}

.text-noc-label {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
  font-family: Roboto Flex, sans-serif;
}

.text-noc-light {
  font-size: 0.9rem;
  color: #8E8E8E; 
  font-weight: 300;
  font-family: Roboto Flex, sans-serif;
}

.or-seprator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
}

.seprator-line {
  width: 35%;
  height: 1px;
  background: #ccc;
  margin: 1em 0;
  //   padding: 1px;
}

.logo-img{
  height: 72px;
  width: 72px;
}

#login-sidebar {
  background-repeat: no-repeat;
  background-size: cover;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.signup-form-wrapper{
  position: relative;
  padding-bottom: 45px;
  .btn-wrapper{
    position: absolute;
    bottom: 0;
    max-width: 445px;
    bottom: -10px;
  }
}

.signup-form-wrapper > div{
  max-height: 250px;
  overflow-y: scroll;
}

.text-wel{
  font-weight: 400;
  font-size: 24px;
  line-height: 49px;
  color: white;
}
.text-noc{
  font-weight: 700;
  font-size: 35px;
  line-height: 49px;
  color: white;
}
.text-noc-b{
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  color: white;
}

.logo-des{
width: 200px;
height: 100%;
   border-radius: 10px;
}

.left-side{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

}