.list {
  padding: 0;
  margin: 15px 0;
  padding-left: 15px;
  li{
    margin-bottom: 5px;
    &:before {
        content: "";
        width: 10px;
        height: 10px;
        background: #333;
        display: inline-block;
        border-radius: 10px;
        // margin-right: 8px;
        position: absolute;
        margin-top: 4px;
        left: 15px;
      }
  }
}
