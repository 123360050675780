@import "../../../assets/styles/global.scss";

.top-info-view {
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 130px;
  align-items: center;
  justify-content: space-around;

  .separate-bar {
    height: 70px;
    width: 1px;
    opacity: 0.5;
    background: #ccc;
    margin: 8px 0px;
    border-radius: 2px;
  }
  .text-container {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 500;
      font-family: Roboto, sans-serif;
      font-size: 0.9rem;
      color: #000;
    }

    .value {
      font-weight: 900;
      font-family: Roboto, sans-serif;
      font-size: 1.5rem;
      color: #000;
    }

    .value-loading {
      font-family: Roboto, sans-serif;
      font-size: 1rem;
      color: #000;
    }
  }

  .text-container:nth-child(1) .value {
    color: $text-green-color;
  }

  .text-container:nth-child(3) .value {
    color: $text-dark-orange;
  }

  .text-container:nth-child(5) .value {
    color: $text-sky-blue;
  }

  .text-container:nth-child(7) .value {
    color: $text-light-purple;
  }
}

@media (max-width: 425px) {
  .separate-bar {
    display: none;
  }
}
