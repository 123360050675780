$primary-color: #0175b6;
$primary-color-200: #96bfdb;
$primary-color-dark: #01588a;
$light-gray-color: #ccc;
$primary-color-light: #eef5fd;
$text-color: #555453;
$secondary-color: rgb(250, 191, 143);
$secondary-color-light: #fcd5b4;

$text-green-color: #26a26b;
$text-dark-orange: #cf5300;
$text-sky-blue: #0c7bb6;
$text-light-purple: #554b86;
