.col-indicator {
  height: 100vh;
}

.indicator-map-container {
  border-radius: 12px;
  padding: 16px;

  .orange-dot {
    color: orange;
  }
  .sky-blue {
    color: skyblue;
  }
  .green-dot {
    color: green;
  }
}
