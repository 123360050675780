.fieldTable {
    >.row {
        // padding-left: 0 !important;

        >div {

            &:first-child {
                padding: 10px;
                font-weight: bold;
                background-color: #eee;
            }

            &:last-child {
                padding: 10px;
            }
        }


    }

    .fieldTitle {
        background-color: #eee;
    }

    .col-md-6 {
        border-bottom: 1px solid #ccc;
        .row {
            height: 100%;
            > div{
                padding: 10px;
            }
        }
    }
}