.smart-indicator-label{
    cursor: pointer;
    height: 6px;
    width: 20px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 5px;
    &.bg-light {
        background-color: #dee2e6!important;
    }
}