.charts-view {
  display: flex;
  flex-wrap: wrap;
  height: 300px;
  padding: 16px 0px;
  border-radius: 10px;
  

  
  .seprator {
    height: auto;
    width: 1px;
    opacity: 0.3;
    background: #ccc;
    margin: 8px 0px;
    border-radius: 2px;
  }
}
.progressContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  // overflow: hidden;
  max-height: 300px;

  .hide-scrollbar {
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }

  .progress {
    height: 8px;
  }
}

.heading {
  font-weight: 500;
  font-size: 12px;
}

.justify-content-evenly {
  justify-content: space-evenly;
}


@media (max-width: 600px) {
  .seprator {
     display: none;
  }
}

