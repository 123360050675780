.cont {
  display: flex;
  .card-body {
    &.shadow{
      box-shadow: 2px 2px 12px 2px #eee;
    }
    border-color: #0075b6;
    border-radius: 2rem;
    .two-panel-flex {
      display: flex;
      gap:10px;
      padding: 2rem 0.5rem;
      .second{
        width:75%;
      }
      .panel-content-container {
        border-radius: 1rem;
        border: 1px solid #8ea69145;
        overflow-x: hidden;
        overflow-y: auto;
        .panel-tab-heading {
          color: #0075b6;
          background-color: #eef5fd;
          border-bottom: 0.1rem solid #d8e2dd;
          text-align: center;
          margin:0px;
          padding:0px;
          .panel-heading-content{
            margin:0px;
            padding:10px;
          }
        }
        .panel-tab-content {
          padding: 1rem;
          .btn {
            cursor: pointer;
            width: 13rem;
            margin-left: 0.5rem;
            color: #0075b6;
            background-color: white;
            border: 1px solid #0075b6;
            padding:.5em;
            border-radius: 0.3em;
          }
          
          .tabs {
            display: flex !important;
            .active {
              cursor: pointer;
              width: 13rem;
              font-size: 1rem;
              color: white !important;
              background-color: #0075b6 !important;
              border: 1px solid #0075b6 !important;
            }
          }
        }
        .table-container {
          background-color: transparent;
          .form-content {
            display: flex;
            flex-grow: 1;
            position: relative;
            overflow: hidden;
            .list-container {
              width: 100%;
              .card-header {
                .btn-circle {
                  border: none;
                  border-radius: 50%;
                  width: 3rem;
                  height: 3rem;
                }
              }
              .datatable-container {
                min-height: 15rem;
                .datatable-wrapper {
                  position: relative;
                  clear: both;
                  zoom: 1;
                  .datatable-header {
                    padding: 1.25rem 1.25rem 0 1.25rem;
                    margin: 0 0 1.25rem 1.25rem;
                    .float-right {
                      float: right !important;
                      display: inline-flex;
                    }
                    .datatable-filter {
                      float: right;
                      text-align: right;
                      position: relative;
                      display: block;
                      margin: 0 0 1.25rem 1.25rem;
                    }
                  }

                  .table {
                    position: static;
                    zoom: 1;
                    border-bottom: none !important;
                    margin: 0 auto;
                    border-collapse: separate;
                    border-spacing: 0;
                  }
                  .datatable-footer {
                    padding: 1.25rem 1.25rem 0 1.25rem;
                    border-top: none !important;
                    .dataTables_info {
                      clear: both;
                      float: left;
                      padding: 0px 10px;
                      margin-bottom: 1.25rem;
                    }
                    .dataTables_paginate {
                      float: right;
                      text-align: right;
                      padding-top: 0.25em;
                      margin: 0 0 1.25rem 1.25rem;
                      .paginate_button {
                        cursor: default;
                        color: #666 !important;
                        border: 1px solid transparent;
                        box-shadow: none;
                        padding: 3px 8px;
                        margin: 0px 3px;
                        box-sizing: border-box;
                        display: inline-block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
