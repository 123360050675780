.activities-container {
  border-radius: 12px;
  gap: 16px;
  .carousel{
    height: 95%;
  }
  .carousel-inner {
    height: 90%;
    border-radius: 12px;
    box-shadow: 0px 0px 8px 0px #0000001a;
    .carousel-item {
        height: 100%;
      img {
        border-radius: 12px;
        object-fit: cover;
        height: 300px;
      }
    }
  }
  .carousel-indicators {
    gap: 8px;
    height: 12px;
    bottom: 12px;
  }
  .carousel-indicators [type="button"] {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    padding-inline: 0px;
    border: none;
    border: 1px solid #ccc;
    background-color: transparent;

    &.active {
      background-color: #0A78B4 !important;
    }
  }
}
